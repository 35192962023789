import * as azureService from './azure.api.service';

const DELETE_RESOURCES_TIMEOUT = 15 * 1000;

export const getDeployDetails = (values, fileLink, deploymentName) => ({
    subscriptionId: values.subscriptionId,
    resourceGroupName: values.resourceGroupName,
    isExistingResourceGroup: values.isExistingResourceGroup,
    deploymentName,
    location: values.location,
    properties: {
        templateLink: {
            uri: fileLink
        },
        parameters: {
            networkSecurityGroupName: {
                value: values.networkSecurityGroupName
            },
            nsgResourceGroupName: {
                value: values.nsgResourceGroupName
            },
            virtualMachineName: {
                value: values.virtualMachineName
            },
            virtualNetworkName: {
                value: values.virtualNetworkName
            },
            subnetName: {
                value: values.subnetName
            },
            adminPublicKey: {
                value: values.adminPublicKey
            },
            vnetResourceGroupName: {
                value: values.vnetResourceGroupName
            },
            adminUsername: {
                value: values.adminUsername
            },
            authType: {
                value: values.authType
            },
            adminPassword: {
                value: values.adminPassword
            },
            enablePublicIP: {
                value: values.enablePublicIP
            },
            proxyHost: {
                value: values.proxyHost
            },
            proxyPort: {
                value: values.proxyPort
            },
            proxyUsername: {
                value: values.proxyUsername
            },
            proxyPassword: {
                value: values.proxyPassword
            },
            awsAccessKey: {
                value: values.awsAccessKey
            },
            awsSecretKey: {
                value: values.awsSecretKey
            }
        },
        mode: 'Incremental'
    }
});

export const acceptVirtualMachineOffer = async (subscriptionId) => {
    const plan = 'rockylinux-9';
    const offer = 'rockylinux-9';
    const publisher = 'erockyenterprisesoftwarefoundationinc1653071250513';
    try {
        const agreementDetails = await azureService.getMarketplaceAgreement(subscriptionId, publisher, offer, plan);
        if (agreementDetails) {
            const {
                properties: {
                    licenseTextLink,
                    privacyPolicyLink,
                    marketplaceTermsLink,
                    retrieveDatetime,
                    signature
                }
            } = agreementDetails;
            await azureService.acceptMarketplaceAgreement(subscriptionId, publisher, offer, plan,
                licenseTextLink, privacyPolicyLink, marketplaceTermsLink, retrieveDatetime, signature);
        }
    } catch (err) {
        throw err;
    }
}

const createResourceGroupIfNeeded = async (deployDetails) => {
    if (!deployDetails.isExistingResourceGroup) {
        await azureService.createResourceGroup(deployDetails.subscriptionId, deployDetails.resourceGroupName, deployDetails.location);
    }
    try {
        await azureService.azureTemplateDeploymentValidation(deployDetails);
    }
    catch (e) {
        if (!deployDetails.isExistingResourceGroup) {
            await azureService.deleteResourceGroup(deployDetails.subscriptionId, deployDetails.resourceGroupName);
        }
        return Promise.reject(e);
    }
}

export const prepareForDeploy = async (deployDetails) => {
    // accept plan offer if needed
    await acceptVirtualMachineOffer(deployDetails.subscriptionId);

    // create resource group if needed
    await createResourceGroupIfNeeded(deployDetails);
};

export const rollback = async (deployDetails) => {
    const {isExistingResourceGroup, subscriptionId, resourceGroupName, deploymentName} = deployDetails;
    try {
        //cancel is needed in case the user wants to delete an on-going deployment (not failed)
        await azureService.cancelDeployment(subscriptionId, resourceGroupName, deploymentName);
    }
    catch {
        // eslint-disable-next-line
        console.log('deployment could not be cancelled');
    }
    const rollbackFunction = isExistingResourceGroup ? deleteVmAndDescending : deleteEntireResourceGroup;
    await rollbackFunction(deployDetails);
};

const deleteEntireResourceGroup = async ({subscriptionId, resourceGroupName}) => {
    await azureService.deleteResourceGroup(subscriptionId, resourceGroupName);
};

const deleteVmAndDescending = async ({subscriptionId, resourceGroupName, properties}) => {
    const vmName = properties.parameters.virtualMachineName.value;
    try {
        const vm = await azureService.getVm(subscriptionId, resourceGroupName, vmName);
        const diskName = vm.properties.storageProfile.osDisk.name;
        let timeout;

        await azureService.deleteVM(subscriptionId, resourceGroupName, vmName);

        //Polling on the vm, to check if it was deleted
        const interval = setInterval(async () => {
            try {
                await azureService.getVm(subscriptionId, resourceGroupName, vmName);
            }
            catch {
                //If vm doesn't exists -> it was deleted successfully
                clearTimeout(timeout);
                clearInterval(interval);
                await deleteOtherResources(subscriptionId, resourceGroupName, vmName, diskName);
            }
        }, 30* 1000);

        timeout = setTimeout(async () => {
            //even if vm is not deleted yet, just go on to delete the rest of the resources
            clearInterval(interval);
            await deleteOtherResources(subscriptionId, resourceGroupName, vmName, diskName);
        },5 * 60 * 1000)
    }

    catch {
        //vm doesn't exist
        await deleteOtherResources(subscriptionId, resourceGroupName, vmName);
    }
};

const deleteOtherResources = async (subscriptionId, resourceGroupName, vmName, diskName) => {
    let timeout;

    //Should retry in case of error, to cover cases that the error is because the resource is associated to a resource that is not deleted yet
    //for example, NIC should be deleted before PIP/NSG can be deleted
    //note: in case a resource doesn't exist, Azure api returns nothing (no error)
    const interval = setInterval(async () => {
        try {
            await azureService.deleteNetworkInterface(subscriptionId, resourceGroupName, `${vmName}-nic`);
            if (diskName) await azureService.deleteDisk(subscriptionId, resourceGroupName, diskName);
            await azureService.deletePublicIP(subscriptionId, resourceGroupName, `${vmName}-pip`);
            await azureService.deleteSecurityGroup(subscriptionId, resourceGroupName, `${vmName}-nsg`);
            clearInterval(interval);
            clearTimeout(timeout);
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.log(err.data.error.message);
        }
    }, 5000);
    timeout = setTimeout(() => {
        clearInterval(interval);
    }, DELETE_RESOURCES_TIMEOUT);
};
